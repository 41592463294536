
import { Companies } from "@/api";
import { Company, ContractType, PrivateCompany } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";
import Delegates from "@/components/companies/Delegates.vue";
import RepresentativesCreate from "@/components/companies/RepresentativesCreate.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Delegates,
    PageTitle,
    ValidationProvider,
    ValidationObserver,
    RepresentativesCreate,
    PhoneInput
  },
  mixins: [PhoneCodes]
})
export default class CompaniesCreatePage extends Vue {
  tab = 0;
  companyId: string | null = null;
  company: Company = {
    name: "",
    contractTypeId: 0,
    address: "",
    textNote: "",
    vat: "",
    exist: 0,
    companyRepresentatives: []
  };
  isLoading = false;
  privateCompany: PrivateCompany = {
    name: "",
    surname: "",
    cellPhone: undefined,
    passport: "",
    birthday: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    genderId: undefined,
    textNote: "",
    email: "",
    contractTypeId: "",
    exist: 0
  };
  menuPrivate = false;
  genders = [
    {
      name: "Male",
      id: 1
    },
    {
      name: "Female",
      id: 2
    }
  ];
  terms = [
    {
      name: "COD",
      id: 2
    },
    {
      name: "EFT",
      id: 3
    }
  ];
  contractTypes: Array<ContractType> = [];
  tableHeader = {
    title: "Delegates",
    icon: "mdi-account-multiple"
  };
  headers = [
    { text: "Name", value: "name", align: "left" },
    { text: "ID", value: "delegatesId", align: "center" },
    { text: "Phone number", value: "phoneNumber", align: "center" },
    { text: "Address", value: "address", align: "center" },
    { text: "Last event", value: "lastEvent", align: "center" },
    { text: "Date", value: "date", align: "center" }
  ];
  date = new Date().toISOString().substr(0, 10);
  menu = false;
  menu2 = false;
  menu3 = false;
  headersTitle = [
    { icon: "mdi-calendar-month", header: "Company Profile Creator" }
  ];
  leadType = 0;
  leadTypes = [
    {
      id: 0,
      name: "Company"
    },
    {
      id: 1,
      name: "Private"
    }
  ];

  get buttonsArray() {
    const buttons = [
      {
        text: "Back",
        icon: "mdi-chevron-left",
        color: "white",
        action: "back",
        disabled: this.isLoading
      }
    ];
    if (this.tab === 0 || this.leadType === 1) {
      buttons.push({
        text: "Save",
        icon: "mdi-pencil-box-outline",
        color: "dark",
        action: "save",
        disabled: this.isLoading
      });
    }
    return buttons;
  }

  async mounted() {
    try {
      this.contractTypes = await Companies.contractTypes();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onBack() {
    await this.$router.push({ name: "CompaniesList" });
  }
  async onSave() {
    if (this.leadType === 0) {
      (this.$refs.form as Vue & {
        click: () => void;
      }).click();
    } else if (this.leadType === 1) {
      (this.$refs.formPrivate as Vue & {
        click: () => void;
      }).click();
    }
  }
  setDateOfBirth() {
    if (
      this.privateCompany.passport.length >= 6 &&
      !isNaN(+this.privateCompany.passport)
    ) {
      let splitedId: any = this.privateCompany.passport;
      splitedId = this.privateCompany.passport.match(/.{1,2}/g);
      const year =
        splitedId[0] > 15 ? "19" + splitedId[0] : "20" + splitedId[0];
      const month = splitedId[1] > 12 ? 12 : splitedId[1];
      const day = splitedId[2] > 31 ? 31 : splitedId[2];

      const preFormattedDate = `${year}-${month}-${day}`;

      this.privateCompany.birthday = preFormattedDate;
    }
  }
  async save() {
    try {
      this.isLoading = true;
      if (this.leadType === 0) {
        this.company.exist = Number(this.company.exist);

        const res = await Companies.create(this.company);
        this.$success("<strong>Success!</strong> Company has been created!");
        this.isLoading = false;
        this.companyId = res.id;
        this.tab += 1;

        // if (this.$route.name === "CompaniesCreate") {
        //   await this.$router.push({
        //     name: "CompaniesList",
        //     params: { id: res.id.toString() }
        //   });
        // }
      } else if (this.leadType === 1) {
        this.privateCompany.exist = Number(this.privateCompany.exist);
        await Companies.createPrivate(this.privateCompany);
        await this.$success(
          "<strong>Success!</strong> Private company has been created!"
        );

        if (this.$route.name === "CompaniesCreate") {
          this.$router.push({
            name: "CompaniesList"
            // params: { id: res.id.toString() }
          });
        }
        this.isLoading = false;
      }
    } catch (e) {
      const err = e as any;

      if (this.leadType === 0) {
        (this.$refs.companyForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
        await this.$error(err.errors);
      } else if (this.leadType === 1) {
        (this.$refs.privateForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
        await this.$error(err.errors);
      }
      this.isLoading = false;
    }
  }
  get getFormattedBirthDay() {
    return moment(this.privateCompany.birthday).format("DD/MM/YYYY");
  }
}
